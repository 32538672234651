<template>
  <div class="tokenre-container">

    <h3 v-if="!tokenQuery"> Token Bulunmamadı, pencereyi kapabilirsin.</h3>
  </div>
</template>

<script>
  import Helper from '../../helper';

  export default {
    name: "src-pages-body-main-TokenRedirect",
    data() {
      return {
        tokenQuery: null
      };
    },

    methods: {
      tokenRedirect(token) {
        this.api.user.tokenRedirect(token)
          .then(({data}) => {
            const result = data;
            if (result.success) {
              if(result.data.login) {
                //login

                if (result.token) {
                  window.localStorage.setItem("token", result.token);
                }


                //burda bir istisna var normalde bu alttaki if kod yok ama servisten visitor_id data  içinde geliyor redirect_token'a özel bi durum
                if(result.data.visitor_id){
                  result.visitor_id = result.data.visitor_id;
                }

                Helper.setInitialValuesAfterInit(result, this.route, this.route);


                if(result.visitor_id) {
                  window.localStorage.setItem("visitor_id", result.visitor_id);
                }

                if (result.data.user) {
                  this.EventBus.$emit("listenPusherChannelToGetInfoForUser");
                }

              } else {
                //login degil
              }
              this.$router.push(result.data.redirect_url);
              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            console.log(err)
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    watch: {

      tokenQuery(newVal, oldVal) {
        if (newVal) {
          this.tokenRedirect(newVal);
        }
      },

      "$store.state.routerParams": function (newVal, oldVal) {
        if (newVal && oldVal) {
        }
      }
    },

    created() {
      this.tokenQuery = this.route.query.t;


    }
  }
</script>

<style scoped lang="scss">
  .tokenre-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
